import fp from 'lodash/fp';

export const DATA = 'DATA';
export const LOGOUT = 'LOGOUT';

//action creators
export const receiveData = (data) => ({
    type: DATA,
    payload: data
});
export const logout = () => ({type: LOGOUT});

// selectors
export const selectObject = path => fp.flow([
    fp.get('jsonapi.objects'),
    fp.get(path),
]);

export const selectRawAssignments = fp.flow([
    selectObject('Assignment'),
    fp.values,
]);

export const selectAssignments = state => {
    const raw = selectRawAssignments(state);

    return fp.map(
        asg => {
            const classroom = selectObject([
                asg.relationships.classroom.data.type,
                asg.relationships.classroom.data.id
            ])(state);
            return fp.set('rel.classroom', classroom, asg);
        },
        raw
    );
}

export const selectSession = fp.flow([
    selectObject('Session'),
    fp.values,
    fp.first
]);

const initialState = {
    userSession: null,
    objects: {
        Session: {},
        Classroom: {},
        ClassroomSession: {},
        Assignment: {},
    }
}

const reduceObject = (state, data) => {
    const path = `objects.${data.type}.${data.id}`;
    return fp.set(path, {...data}, state);
}


export default function(state=initialState, action) {
    const {type, payload} = action;
    switch(type) {
        case DATA: {
            if(payload.data.type) {
                return reduceObject(state, payload.data);
            } else if(payload.data.length) {
                let newState = state;
                fp.forEach(item => {
                    newState = reduceObject(newState, item);
                }, payload.data);
                return newState;
            }
            return state;
        }
        case LOGOUT:
            return initialState;
        default: return state;
    }
}
