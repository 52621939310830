import React from 'react';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';

import MaterialTable from 'material-table';

import {useApi} from 'ctlsme/components/Api';
import {selectAssignments} from 'ctlsme/ducks/jsonapi';

export default function Assignments() {
    const api = useApi();
    const assignments = fp.map(
        a => ({data: a}),
        useSelector(selectAssignments)
    );

    const columns = [
        {title: 'Course Name', field: 'data.rel.classroom.attributes.courseName'},
        {title: 'Assignment Name', field: 'data.attributes.assignmentName'},
        {title: 'Status', field: 'data.attributes.status', lookup: {
            Viewed: 'Viewed',
            Assigned: 'Assigned',
            Finished: 'Finished',
            Submitted: 'Submitted',
            'Ready to Score': 'Ready to Score',
            
        }},
        {title: 'Start', field: 'data.attributes.startDate', 
            type: 'date', filtering: false
        },
        {title: 'End', field: 'data.attributes.endDate', 
            type: 'date', filtering: false
        },
    ];
    const refreshAssignments = async () => {
        let classrooms = await api.fetchClassrooms();
        fp.forEach(c => api.fetchClassroomData(c), classrooms.data);
    }

    return (
        <MaterialTable 
            title="Your assignments"
            columns={columns} 
            data={assignments}
            options={{
                search: false,
                filtering: true,
            }}
            actions={[
                {
                    icon: 'refresh', tooltip: 'refresh', isFreeAction: true,
                    onClick: refreshAssignments
                }
            ]}
        />
    );
}

