import React from 'react';

import {makeStyles} from '@material-ui/core';

import LoginForm from 'ctlsme/components/LoginForm';
import Footer from 'ctlsme/components/Footer';


const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

    },
});


export default function HomePage() {
    const classes = useStyles();
    console.log('Render HomePage');

    return (
        <div className={classes.root}>
            <h1>CTLS Assignment Checker</h1>
            <LoginForm />
            <Footer/>
        </div>
    );
}


