import React from 'react';
import {Provider} from 'react-redux';
import ApiProvider from 'ctlsme/components/Api';
import Router from 'ctlsme/components/Router';
import store from 'ctlsme/store';


export default function App() {
    return (
        <Provider store={store}>
            <ApiProvider root={process.env.REACT_APP_API_ROOT}>
                <Router/>
            </ApiProvider>
        </Provider>
    );
}
