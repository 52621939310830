import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {url_for} from 'ctlsme/components/Router';
import BasePage from 'ctlsme/components/BasePage';
import AssignmentsTable from 'ctlsme/components/AssignmentsTable';
import {selectSession} from 'ctlsme/ducks/jsonapi.js';

export default function AssignmentsPage() {
    const history = useHistory();
    const session = useSelector(selectSession);

    let title = 'Unknown Student';
    if(session) {
        let parts = [
            session.attributes.firstName,
            session.attributes.middleName,
            session.attributes.lastName,
        ];
        title = parts.join(' ');
    }

    useEffect(() => {
        if(!session) {
            history.push(url_for('home'));
        }
    }, [history, session]);

    return (
        <BasePage title={title}>
            <AssignmentsTable />
        </BasePage>
    );
}
