import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import fp from 'lodash/fp';

import {TextField, Button, makeStyles} from '@material-ui/core';

import {useApi} from 'ctlsme/components/Api';
import {url_for} from 'ctlsme/components/Router';

const useStyles = makeStyles({
    root: {
        maxWidth: 400
    }
});

export default function LoginForm() {
    const api = useApi();
    const history = useHistory();
    const classes = useStyles();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async ev => {
        ev.preventDefault();
        await api.login({username, password});
        let classrooms = await api.fetchClassrooms();
        fp.forEach(c => api.fetchClassroomData(c), classrooms.data);
        console.log('pushing assignments');
        history.push(url_for('assignments'));
    }

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <TextField fullWidth label="Username" 
                value={username} onChange={e => setUsername(e.target.value)}
            />
            <TextField fullWidth type='password' label="Password" 
                value={password} onChange={e => setPassword(e.target.value)}/>
            <Button type="Submit">Login</Button>
        </form>
    )
}
