import React from 'react';

import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        padding: 8,
        backgroundColor: 'lightgrey',
    }
});

export default function Footer() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <small>
                This website was created by 
                <a href="https://www.arborian.com">Arborian Consulting, LLC</a>,
                and is not affiliated with the Cobb County School District,
                Microsoft, or Education in Cities.
                Copyright 2020 Arborian Consulting, LLC.
                All Rights reserved.
            </small>
        </div>
    );
}
