import React from 'react';

import {AppBar, Toolbar, Button, makeStyles} from '@material-ui/core';

import {useApi} from 'ctlsme/components/Api';
import Footer from 'ctlsme/components/Footer';

const useStyles = makeStyles({
    flex: {
        flex: 1
    },
    spacer: {
        height: 102
    }
});

export default function BasePage({title, children}) {
    const api = useApi();
    const onLogout = () => api.logout();
    const classes = useStyles();

    return (
        <div>
            <AppBar position="static">
                <Toolbar>
                    <h1>{title}</h1>
                    <div className={classes.flex}/>
                    <Button color="inherit" onClick={onLogout}>Logout</Button>
                </Toolbar>
            </AppBar>
            {children}
            <div className={classes.spacer}/>
            <Footer/>
        </div>
    )
}
