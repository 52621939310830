import React from 'react';

import {useDispatch} from 'react-redux';
import {receiveData, logout} from 'ctlsme/ducks/jsonapi'; 
import {url_for, history} from 'ctlsme/components/Router';

class Api {
    constructor(root, dispatch) {
        this._root = root;
        this._dispatch = dispatch;
        this._directory = null;
        this._token = null;
    }

    directory = async () => {
        if(this._directory) {
            return this._directory;
        }
        let resp = await fetch(this._root);
        let json = await resp.json();
        this._dispatch(receiveData(json));
        return json;
    }

    login = async (values) => {
        let dir = await this.directory();
        let resp = await fetch(dir.data.links.session, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
        });
        let json = await resp.json();
        this._userSession = json;
        this._dispatch(receiveData(json));
        this._token = json.data.attributes.token;
    }

    logout = () => {
        this._dispatch(logout());
        this._token = this._directory = null;
        history.push(url_for('home'))
    }

    fetch = async (url, options={}) => {
        let {headers={}, ...otherOptions} = options;
        headers = {
            'Content-Type': 'application/json',
            ...headers,
        };
        if(this._token) {
            headers['Authorization'] = `Bearer ${this._token}`
        }
        return fetch(url, {
            ...otherOptions,
            headers
        });
    }

    fetchClassrooms = async () => {
        console.log('Fetching classrooms with self', this);
        let dir = await this.directory();
        let resp = await this.fetch(dir.data.links.classrooms);
        let json = await resp.json();
        this._dispatch(receiveData(json));
        return json;
    }

    fetchClassroomData = async c => {
        let resp = await this.fetch(c.links.create_session, {method: 'POST'});
        let json = await resp.json();
        this._dispatch(receiveData(json));

        // fetch assignments
        console.log('Session', this._userSession);
        resp = await this.fetch(
            json.data.links.assignments + this._userSession.data.id
        );
        json = await resp.json();
        this._dispatch(receiveData(json));
    }

}

const Context = React.createContext();

export const useApi = () => React.useContext(Context);


export default function Provider({root, children}) {
    const dispatch = useDispatch();
    console.log('new Api', root, dispatch);
    const api = new Api(root, dispatch, history);

    return <Context.Provider value={api}>{children}</Context.Provider>
}
