import React from 'react';
import * as R from 'react-router-dom';

import {reverse} from 'named-urls';
import {createBrowserHistory} from 'history';

import HomePage from 'ctlsme/pages/HomePage';
import AssignmentsPage from 'ctlsme/pages/AssignmentsPage';

export const history = createBrowserHistory();

export const routes = {
    home: {path: '/'},
    assignments: {path: '/assignments'},
};

export const url_for = (route, params) => {
    try {
        let {path} = routes[route];
        return reverse(path, params)
    } catch(e) {
        console.error('Error looking up route', route, params)
        throw(e);
    }
}

export default function Router() {

    return (
        <R.BrowserRouter history={history}>
            <R.Switch>
                <R.Route exact path={routes.home.path} component={HomePage}/>
                <R.Route exact path={routes.assignments.path} 
                    component={AssignmentsPage}/>
            </R.Switch>
        </R.BrowserRouter>
    )
}
